.skeleton {
    position: relative;
    display: -ms-flexbox;
    background: #ddd;
    overflow: hidden;
    margin: 10px 0;
    direction: ltr;
    border-radius: 4px;
}

.skeleton2 {
    position: relative;
    background: #ddd;
    overflow: hidden;
    direction: ltr;
    border-radius: 4px;
}

.skeleton:before, .skeleton2:before {
    -webkit-animation: ph-animation .8s linear infinite;
    animation: ph-animation .8s linear infinite;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, .35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%;
    bottom: 0;
    content: " ";
    left: 50%;
    margin-left: -250%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 400%;
    z-index: 1
}

.skeleton.text {
    width: 40%;
    height: 12px;
}

.skeleton.text_two {
    width: 80%;
    height: 12px;
}

.btn-add-carts {
    width: 2.188rem;
    height: 2.188rem;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6.1875rem;
}

.btn-add-cart__wide2 {
    width: 100px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton2.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.avatar2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 160px;
    height: 200px;
}

.skeleton.thumbnail2 {
    width: 150px;
    height: 140px;
}

.skeleton.thumbnail3 {
    width: 320px;
    height: 300px;
}

.skeleton.thumbnail4 {
    width: 564px;
    height: 520px;
}

.skeleton.thumbnail5 {
    width: 100px;
    height: 100px;
}

@-webkit-keyframes ph-animation {
    0% {
        -webkit-transform: translate3d(-30%, 0, 0);
                transform: translate3d(-30%, 0, 0)
    }

    to {
        -webkit-transform: translate3d(30%, 0, 0);
                transform: translate3d(30%, 0, 0)
    }
}

@keyframes ph-animation {
    0% {
        -webkit-transform: translate3d(-30%, 0, 0);
                transform: translate3d(-30%, 0, 0)
    }

    to {
        -webkit-transform: translate3d(30%, 0, 0);
                transform: translate3d(30%, 0, 0)
    }
}
